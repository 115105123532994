module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-176e3afc69/3/buildhome/.yarn_cache/gatsby-plugin-google-analytics-npm-5.11.0-4008dd33a7-147049b875.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172946597-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../buildhome/.yarn_cache/gatsby-plugin-heap-npm-1.1.0-927aa3984f-4df9ab8253.zip/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1394991606","enableOnDevMode":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-5272564763/3/buildhome/.yarn_cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-7abbac1ee9.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-523b1c1768/3/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monash Human Power","short_name":"MHP","start_url":"/","background_color":"black","theme_color":"black","display":"minimal-ui","icon":"src/images/MHP_logo_green_black.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6017d5511b1dadc6188566139ee1388"},
    },{
      plugin: require('../../../buildhome/.yarn_cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-373724406b.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":0},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-71df40bdc7/3/buildhome/.yarn_cache/gatsby-plugin-styled-components-npm-6.12.0-67312fa5b9-913754f2ca.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-netlify-cms-virtual-ad4261c8d3/3/buildhome/.yarn_cache/gatsby-plugin-netlify-cms-npm-7.12.1-81bdb129b7-b59c45f799.zip/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-078e502237/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
